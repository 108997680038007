@font-face {
  font-family: 'Inter Bold';
  src: local("Inter-Bold"),url("./Inter-Bold.ttf") format("truetype");
  font-weight: 100;
  font-display: swap
}

@font-face {
  font-family: 'Inter Black';
  src: local("Inter-Black"),url("./Inter-Black.ttf") format("truetype");
  font-weight: 100;
  font-display: swap
}

@font-face {
  font-family: 'Inter Regular';
  src: local("Inter-Regular"),url("./Inter-Regular.ttf") format("truetype");
  font-weight: 100;
  font-display: swap
}

@font-face {
  font-family: 'Inter Light';
  src: local("Inter-Light"),url("./Inter-Light.ttf") format("truetype");
  font-weight: 100;
  font-display: swap
}

@font-face {
  font-family: 'Inter Medium';
  src: local("Inter-Medium"),url("./Inter-Medium.ttf") format("truetype");
  font-weight: 100;
  font-display: swap
}

@font-face {
  font-family: 'Inter Extra Bold';
  src: local("Inter-ExtraBold"),url("./Inter-ExtraBold.ttf") format("truetype");
  font-weight: 100;
  font-display: swap
}

@font-face {
  font-family: 'Inter Extra Light';
  src: local("Inter-ExtraLight"),url("./Inter-ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-display: swap
}
