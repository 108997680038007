@import "../font/cdk-font";
[class*="cdk-text-danger"] {
  color: var(--cdk-color-danger-primary);
}

[class*="cdk-text-success"] {
  color: var(--cdk-color-success-secondary);
}

[class*="cdk-text-warning"] {
  color: var(--cdk-color-warning-terciary);
}

[class*="cdk-text-info"] {
  color: var(--cdk-color-info-primary);
}

[class*="cdk-text-default"] {
  //color: var();
}

[class*="text-bold"] {
  font-weight: bold;
}

[class*="text-underline"] {
  text-decoration: underline;
}
