button[class*='cdk-button-'] {
  width: 100%;
  white-space: nowrap;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: .3125rem;
  cursor: pointer;
  border: none;
  outline: none;
  color: white;

  &:not(:disabled) {
    &:hover {
      -webkit-filter: brightness(110%);
      -webkit-transition: all .2s ease;
      -moz-transition: all .2s ease;
      -o-transition: all .2s ease;
      -ms-transition: all .2s ease;
      transition: all .2s ease
    }
  }

  &:active {
    border: none;
    outline: none
  }

  &:disabled {
    opacity: .7;
    cursor: default;
  }

  font-size: 1.2rem;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }

  @media only screen and (min-width: 768px) {
    padding: .3rem;
  }

  @media only screen and (min-width: 992px) {
    padding: .5rem;
    font-size: 1rem
  }

  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}
