:root {
  --cdk-color-danger-primary: #FFB5B5;
  --cdk-color-danger-secondary: #540000;

  --cdk-color-success-primary: #c3ffb5;
  --cdk-color-success-secondary: #00540d;

  --cdk-color-warning-primary: #fff8b5;
  --cdk-color-warning-secondary: #543d00;
  --cdk-color-warning-terciary: #FBA500;

  --cdk-color-info-primary: #2f80ed;

  --cdk-color-default-secondary: rgba(224, 224, 224, 0.5);
  --cdk-color-default-primary: #bdbdbd;
  --cdk-color-default-terciary: #828282;
}
