.cdk-label {
  font-size: .875rem;
  font-family: 'Inter Regular';
  color: #000;
  width: 100%;
  margin-bottom: 0.5rem;
  cursor: pointer;

  span[class*='required'] {
    color: red;
    margin-inline: .2rem;
  }
}

.cdk-input, select[class*='cdk-select'] {
  border-radius: .3125rem;
  border: solid 1px rgba(0, 0, 0, .4);
  width: 100%;
  height: 2.475rem;
  outline: none;
  margin-bottom: 1.481vh;

  &:read-only {
    cursor: not-allowed;
    caret-color: #f2f2f2;
    background-color: #f2f2f2
  }

  :focus {
    border: 1px solid #5B5B5B
  }

  &:is([class*='ng-touched']) {
    &:is([class*='ng-dirty']) {
      &:is([class*='ng-invalid']) {
        border: 1px solid red
      }

      &:is([class*='ng-valid']) {
        border: 1px solid #1D315C
      }
    }

  }
}

select[class*='cdk-select'] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  padding: .5rem;
  cursor: pointer !important;
}

input[class*='cdk-checkbox'] {
  cursor: pointer;
  appearance: none;
  background-color: #1d315c;
  min-width: 1.625rem;
  min-height: 1.625rem;
  max-width: 1.625rem;
  max-height: 1.625rem;
  width: 1.625rem;
  height: 1.625rem;
  margin-right: .5rem;
  border-radius: .1875rem;
  border: solid 1px #6f89a4;
  transform: translateY(-.075em);
  display: flex;
  justify-content: center;
  align-items: center;

  &:checked:before {
    content: "\2713";
    color: #fff
  }
}

.cdk-invalid-form-entry {
  color: red
}
