@import "assets/design-system/cdk-design-system.scss";

body,
html {
  font-family: "Inter Regular", serif;
  background-color: #F2F2F2;
  overflow-x: hidden;
}

.cdk-inter-bold {
  font-family: Inter Bold, serif
}

.cdk-text-gray {
  color: #5b5b5b
}

.cdk-text-black {
  color: #000
}

.cdk-clickable {
  cursor: pointer;
}

div[class*='-title'] {
  span[class*='-label'] {
    vertical-align: top;
    font-size: 1.375rem;
    font-family: "Inter Bold";
    color: rgba(0, 0, 0, 0.5);
  }
}


@media only screen and (max-width: 768px) {
  [class*="d-border-top"] {
    border-radius: 1.25rem 1.25rem 0 0;
  }

  [class*="d-md-border-top-right"] {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

@media only screen and (min-width: 768px) {
  [class*="w-md-50"] {
    width: 50%;
  }

  [class*="d-md-border-top-left"] {
    border-top-left-radius: 1.25rem;
  }

  [class*="d-md-border-top-right"] {
    border-top-right-radius: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .checkWidth {
    flex-direction: column-reverse;
  }
}

div[id='-cars'] {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-basis: 100%;

  @media only screen and (max-width: 600px) {
    gap: .5rem;
  }

  @media only screen and (min-width: 600px) {
    gap: 1rem;
  }

  @media only screen and (min-width: 992px) {
    gap: 1.5rem;
  }

  @media only screen and (min-width: 1200px) {

  }
}

section[class*="cdk-vehicle-card"],
section[class*="cdk-announcement-card"] {
  margin: 0 auto;
  cursor: pointer;
  border-radius: 1.25rem;
  box-shadow: 0 2px 2px #00000040;
  padding-bottom: .5rem;

  @media only screen and (max-width: 600px) {
    max-width: 70%;
    width: 70%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 50%;
    width: 50%;
  }

  @media only screen and (min-width: 768px) {
    max-width: 18rem;
    width: 18rem;
  }

  &[class*="inactive"] {
    opacity: .4;
  }

  img {
    width: 100%;

    @media only screen and (max-width: 600px) {
    }

    @media only screen and (min-width: 768px) {
      height: 13.5rem;
    }

    @media only screen and (min-width: 1200px) {
      height: 15.625rem;
    }

    border-radius: 1.25rem 1.25rem 0 0;
  }

  div[class="-body"] {
    display: flex;
    flex-direction: column;
    border-radius: 1.25rem;
    padding: 1rem;

    span[class="brand"], span[class="resume"] {
      font-family: 'Inter Regular';
    }

    span[class="model"] {
      font-family: 'Inter Bold';
    }
  }

  div[class="-footer"] {
    width: 100%;
    padding: 0 .75rem .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span[class="price"] {
      font-family: 'Inter Bold';
      font-size: 1.771rem;
    }
  }

  section[class*='cdk-badge'] {
    bottom: 0;
  }
}

section[id='cdk-purchase'], section[id='cdk-proposal'], section[id='cdk-bid'] {
  width: 100%;
  display: flex;
  flex-direction: row;

  margin-top: .625rem;
  min-height: 5.75rem;

  div[class*='-bid'], div[class*='-proposal'], div[class*='-purchase'] {
    padding: .5rem;
    flex-basis: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;

    div[class*="-metadata"] {
      flex-basis: 100%;
      justify-self: flex-start;

      span[class*="-brand"], span[class*="-resume"] {
        font-family: 'Inter Regular';
      }

      span[class*="-model"] {
        font-family: 'Inter Bold';
        font-size: 1.196rem;
      }

      span[class*="-price"] {
        margin: 0 1rem;
        font-family: 'Inter Bold';
        font-size: 2.125rem;
      }
    }

    div[class*="-clockwise"] {

      [class^='cdk-button-'] {
        $max-height: 1.25rem;
        height: $max-height;
        width: 100%;
        font-size: 0.75rem;
        padding: 0;

      }

      div[class*="-timeout"] {
        font-family: 'Inter Regular';
        font-size: .75rem;
        color: #333333;
        text-wrap: nowrap;
        line-height: initial;
      }

      div[class*="-clock"] {
        display: flex;
        font-family: 'Inter Bold';
        line-height: initial;
        font-size: 1.5rem;
        padding: 0;
        margin: 0;
      }

      div[class*="-status"] {
        line-height: initial;
        text-wrap: nowrap;
        font-size: 1.25rem;
        font-family: "Inter Bold";
      }
    }

    div[class*="-price"] {
      justify-self: flex-end;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      height: 100%;
      font-size: 1.196rem;
      font-family: 'Inter Bold';

      ::ng-deep img {
        cursor: pointer;
      }
    }
  }
}
