form[id$="-form"] {

  @media only screen and (max-width: 600px) {
    max-width: 90%;
  }

  @media only screen and (min-width: 600px) {
    max-width: 80%;
  }

  @media only screen and (min-width: 992px) {
    max-width: 60%;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 40%;
  }

  div[class*="cdk-input-group"] {
    margin-block: .5rem;
    column-gap: .5rem;

    div[class$="-item"] {
      width: 100%;
    }

    label[class*="cdk-label"] {
      margin-bottom: .625rem;

      &[class*="inline"] {
        margin-bottom: 0;
        place-self: flex-start;
      }
    }

    a[class="disclaimer"] {
      color: #2f80ed;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  div[id="hidden-content"] {
    div[class$="-item"] {
      width: 100%;
    }

    label[class="cdk-label"] {
      margin-bottom: .625rem;
    }

    a[class="disclaimer"] {
      color: #2f80ed;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  div[id$="-footer"] {
    display: flex;
    background-color: #fff;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;

    button {
      padding-inline: .5rem;
    }
  }
}
