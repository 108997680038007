nav[id$="-navbar"] {

  width: 100%;

  background-color: #1D315C;
  color: white;

  padding: 1rem;

  div[class*="brand"] {
    cursor: pointer;
    padding: 0 2.75rem 1rem;

    span[class*="namespace-version"] {
      cursor: default;
      font-size: 1rem !important;
    }
  }

  div[class*="item"] {
    cursor: pointer;
    padding-right: 1.4375rem;
    padding-left: .25rem;
    display: flex;
    column-gap: .25rem;
    align-items: center;

    cdk-icon {
      margin-top: -.2rem;
    }
  }
}

nav[id$="-navbar-responsive"] {
  background-color: #1D315C;
  color: white;
  padding-right: 1rem;


  top: 0;
  width: 100%;
  z-index: 10000000000;

  div[class*="brand"] {
    display: flex;
    flex-direction: column;

    span[class*="namespace-version"] {
      cursor: default;
      font-size: 1rem !important;
    }

    cursor: pointer;
    padding-inline: 1rem;
    padding-block: .5rem;
    font-size: 2rem;
  }

  div[class*="-content"] {
    flex-basis: 100%;
    div[class*="item"] {
      cursor: pointer;
      padding-left: 1rem;
      display: flex;
      font-size: .8rem;
      align-items: center;
      column-gap: .5rem;
      padding-inline: 1.5rem;
      border-top: 1px solid rgba($color: #fff, $alpha: .2);
      height: 3rem;
    }
  }
}
